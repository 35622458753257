.checkbox-list-container {
  padding: 5px; 
  width: auto;  
  max-width: 100%; 
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

@media (max-width: 1279px) {
  .checkbox-list-container {
    width: 100%; 
  }
}

.checkbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; 
  padding: 5px; 
  border-radius: 8px;
  transition: background-color 0.3s;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
}

.checkbox-item:hover {
  background-color: #e9f3ff;
}

.checkbox-item label {
  font-size: 14px; 
  margin-right: 15px;
  flex: 1;  
  font-weight: 400;
  line-height: 20px; 
  font-family: Poppins;
  color: #667085;
  word-wrap: break-word;  
  overflow-wrap: break-word; 
}

.checkbox-item input {
  margin-right: 10px;
  cursor: pointer;
}

.item-count {
  font-size: 10px; 
  color: #007bff;
  font-weight: bold;
  text-align: center;
  min-width: 10px;
  padding: 5px 8px; 
  background-color: #e6f0ff;
  border-radius: 20px;
  display: inline-block;
}
