.inteiro-teor-button-group {
    display: flex;
    gap: 4px;
    margin-top: 8px;
  }
  
  .inteiro-teor-button {
    flex: 1;
    padding: 10px 16px;
    height: 56px;
    font-size: 12px;
    font-weight: 400;
    background-color: #f9fafb;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: center;
    border-radius: 0;
  
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  
    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  
    &:not(:last-child) {
      border-right: none;
    }
  }
  
  .inteiro-teor-button.active {
    background-color: #f3f3f3;
    border: 1px solid #0069f5;
    color: #566273;
  }
  