.add-tag-container {
  background: #ffffff;
  color: #475467;
  border-radius: 50px;

  p {
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

.modal-tag-container {
  &_header {
    padding: 0 23px;

    &_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #101828;
    }

    &_subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      margin-top: 20px;
      color: #475467;
    }
  }

  &_body {
    padding: 10px 13px;

    &_tab-menu {
      text-align: center;
      padding: 0 128px;

      .rs-nav-item {
        color: #101828;
        &:hover {
          color: rgba(6, 13, 26, 0.722) !important;
        }
      }

      .rs-nav-item-active {
        color: #101828;
      }
    }

    &_content {
      margin-top: 20px;
    }
  }

  &_footer {
    padding: 31px 13px;
    display: flex;
    gap: 10px;
  }
}

.search-tag-container {
  padding: 0 10px;

  &_empty {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: #1d2939;
    padding: 13px 18px;
    border-radius: 8px;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.04),
      0px 1px 3px rgba(0, 0, 0, 0.06);

    h6 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &_search-input {
    background: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &:focus,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &_tags-list {
    height: 225px;
    overflow-y: auto;
    overflow-x: hidden;

    &.empty {
      height: 130px;
    }

    &_tag-container {
      border-top: 1px solid #f2f4f7;
      padding: 16px 0px;
    }
  }

  &_informations {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .rs-input-group {
    &:focus,
    &:focus-within {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

@media (max-width: 1535px) {
  .hide-text {
    display: none;
  }

  .show-icon {
    display: inline;
  }
}
