.custom-steps-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
  
  .custom-steps-item-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .custom-steps-circle {
    width: 30px;
    height: 30px;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .custom-steps-circle-active {
    background-color: #010C28;
    color: white;
  }
  
  .custom-steps-circle-inactive {
    border: 1px solid #717273;
    color: #717273;
    background-color: transparent;
  }
  
  .custom-steps-circle-completed {
    border: 1px solid #010C28;
    color: #010C28;
    background-color: transparent;
  }
  
  .custom-steps-label {
    font-size: 1rem;
  }
  
  .custom-steps-label-active {
    color: #343434;
    font-weight: 500;
  }
  
  .custom-steps-label-inactive {
    color: #6b7280;
    font-weight: 400;
  }
  
  .custom-steps-separator {
    height: 1px;
    width: 56px;
  }
  
  .custom-steps-separator-active {
    background-color: #010C28;
  }
  
  .custom-steps-separator-inactive {
    background-color: #6d6d6d;
  }
  