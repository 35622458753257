@import '../../../vars.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


// ---------------------------------------------------------------
// Essas duas classes controlam a borda do card de CNPJ
// ---------------------------------------------------------------
.card-case-border {
  // border: 1px solid $gray-border-alpha;
  border-radius: 16px ; /* Aplica um arredondamento de 10px em todos os cantos */
  border: 1px solid transparent;
}

.card-case-border:hover {
  // border-radius: 10px;
  border: 1px solid $secondary
}
// ---------------------------------------------------------------


.card-case {
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  margin-bottom: 8px;
  cursor: pointer;
  // box-shadow: 0px 24px 40px -22px rgba(0, 0, 0, 0.15);
  height: 100%;
  border-radius: 16px !important; // EXPLANATION: Sempre colocar no card-case
  border: 1px solid transparent;
  box-shadow: 
  0px 1px 3px 0px rgba(0, 0, 0, 0.01), /* Primeira sombra */
  0px 1px 2px 0px rgba(0, 0, 0, 0.02); /* Segunda sombra */

  .card-case-title,
  .card-case-description,
  .card-case-action {
    padding: 40px 30px;
    
  }

  


  .card-case-title {
    padding: 40px 30px 0px 30px;
    display: block;
    border-radius: 16px 16px 0px 0px; // EXPLANATION: Sempre colocar no card-case
    background-color: $white;
    font-weight: 700;
    color: $white;
    text-align: center;
    // border-radius: 4px 4px 0px 0px;
    font-size: 40px;
  
    h6 {
      font-size: 24px;
      color:#010C28;
      margin-top: 20px;
    }
    .icon-box {
      width: 80px; /* Ajuste o tamanho do círculo */
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F5FAFF; /* Cor de fundo opcional */
      border-radius: 50%; /* Deixa o elemento circular */
      // box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Efeito de sombra opcional */
      margin: 0 auto; /* Centraliza horizontalmente */
  }
  }

  .card-case-description {
    padding: 30px 40px 30px 40px;
    display: flex;
    align-items: center;
    // height: 170px;
    background-color: $white;
    font-family: 'Poppins';
    font-weight: 400;

    p {
      font-size: 16px;
      text-align: center;
      color: #475467;
      overflow: hidden;

      text-overflow: ellipsis;
      display: -webkit-box;

      -webkit-line-clamp: 8;

      -webkit-box-orient: vertical;
    }

    &.no-permission {}
  }

  .card-case-permission {
    padding: 0px 40px 30px 40px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: $white;

    p {
      border: 2px dashed $secondary-lighter;
      padding: 10px;

      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      color: $turivius-dark;
      overflow: hidden;

      text-overflow: ellipsis;
      display: -webkit-box;

      -webkit-line-clamp: 8;

      -webkit-box-orient: vertical;
    }
  }

  .card-case-action {
    padding: 0px 30px 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 0px 0px 16px 16px ; // EXPLANATION: Sempre colocar no card-case pq senão esse cara sobreescreve o fundo

    .btn-card-case-action {
      color: #FFFFFF;
      background-color: #010C28;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      // width: 80%;
      padding: 12px 24px;
      margin-bottom: 16.5px;
      border-radius: 4px !important;

      &.no-permission {
        background-color: $turivius-dark;
      }
    }
  }
}

.turivius-modal {
  &.menu-close {
    left: 60px !important;
    transition: 0.3s;
  }

  &.hidden {
    display: none;
  }

  &.visibility {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 260px;
    background-color: #f2f4f7;
    color: $turivius-dark;
    width: 100%;
    height: 100%;
    transition: 0.3s;

    span {
      font-size: 18px;
    }

    .state-soon {
      border: 1px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $turivius-dark;
      margin-bottom: 3px;
      border-radius: 4px;
      padding: 8px 0;
      cursor: help;
      text-align: center;
      min-height: 56px;

      h6 {
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        -webkit-box-pack: center;
        font-size: 14px;
      }
    }

    .state-soon-popover {
      width: 180px;

      .rs-popover {
        text-align: center !important;
      }
    }

    .rs-input {
      font-size: 18px;
      border: none;
      border-radius: 0px;
      border-bottom: 1px solid #D0D5DD;
      background-color: transparent;
      color: $turivius-dark;
      margin-top: 30px;
      width: 40% !important;

      &:focus,
      &:focus-within {
        outline: none !important;
        box-shadow: none;
      }

      &::placeholder {
        color: $turivius-dark;
      }
    }

    .rs-tag {
      background-color: $white;
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));

      width: fit-content;
      border-radius: 50px;
      color: $turivius-dark;
      font-weight: 700;
    }

    .rs-tag-text {
      font-size: 13px;
      margin-right: 10px;
    }

    .rs-tag-closable.rs-tag-lg {
      padding: 5px 10px;
    }

    .rs-tag-icon-close {
      position: unset !important;
      padding: 0px;
      background-color: #fe7b7b;
      padding: 0px 5px 2px;
      border-radius: 50px;

      .rs-icon {
        vertical-align: middle !important;
        width: 12px !important;
        height: 12px;
        color: #ffffff;
      }
    }

    .rs-checkbox-group {
      .rs-checkbox-checked {
        background-color: $white;
        border: 1px solid $white !important;

        label {
          font-weight: bold;
        }
      }

      .rs-checkbox-wrapper {
        display: none;
      }

      .rs-checkbox-checker {
        width: 100%;
        height: fit-content;
        padding: 0 7px;
        min-height: 0;
        text-align: center;

        label {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 50px;
          text-align: center;
        }
      }

      .rs-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $turivius-dark;
        margin-bottom: 10px;
        border: 1px solid $white;
        font-size: 15px;
        border-radius: 4px;
        min-height: 56px;
      }
    }
  }

  .turivius-modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 280px;
    width: 100%;
    height: 10%;
    transition: 0.3s;

    &.menu-close {
      padding: 0px 80px;
      transition: 0.3s;
    }

    .btn-close-action {
      color: $turivius-dark;
      line-height: 28.44px;
      padding: 8px 36px;
      font-size: 16px;
      transition: 0.3s;
      font-weight: 700;
    }
  }

  .turivius-modal-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 90%;
    padding: 0px 90px;

    .btn-search-action {
      border-radius: 4px;
      background-color: $turivius-dark;
      color: $white;
      line-height: 28.44px;
      padding: 8px 36px;
      font-size: 16px;
      display: flex;
      justify-items: center;
      align-items: center;
      gap: 8px;
    }

    .rs-grid-container-fluid {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 810px) {
  .card-case {
    flex-direction: column;

    .card-case-title,
    .card-case-description,
    .card-case-action {
      width: 100%;
    }

    .card-case-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      padding: 16px 16px 0px;

      h6 {
        font-size: 18px;
        margin-left: 15px;
      }
    }

    .card-case-description {
      padding: 0px 16px 0px;
      height: 200px;
    }

    .card-case-action {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0px 16px 16px;

      .btn-card-case-action {
        display: block;
        // width: 100%;
        border-radius: 4px;
      }
    }
  }
}

@media only screen and (max-width: 499px) {
  .turivius-modal-header {
    padding: 0 !important;
  }

  .turivius-modal-body {
    padding: 0px 50px !important;
  }

  .turivius-modal {
    &.visibility {
      left: 0px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .turivius-modal {
    .turivius-modal-body {
      padding: 0 60px;
    }

    .turivius-modal-header {
      padding: 0 40px;
    }

    &.visibility {
      left: 0px;
    }
  }
}

// Essas classes abaixo controlam o credit popover que aparece no case-card (JURIMETRIA TRABALHSITA)

.popover-upgrade {
  border-radius: 7px;
  width: 280px;
  padding: 16px;
  border: 1px solid #E4E7EC;
  box-shadow: 
      0px 2px 4px -2px rgba(0, 0, 0, 0.06),
      0px 4px 8px -2px rgba(0, 0, 0, 0.10);
  font-family: 'Poppins';
}

.popover-upgrade p {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
}



/* Estilizando apenas o título do popover específico */
.popover-upgrade .rs-popover-title {
  margin-left: 12px;
}

.popover-upgrade .rs-progress-bar {
  height: 3px !important; /* Força a altura reduzida */
}

.btn-upgrade-modal {
  text-decoration: none;
  cursor: auto; /* Mantém o comportamento de botão */  
}

.btn-upgrade-modal:hover {
  text-decoration: none;
  cursor: auto; /* Mantém o comportamento de botão */  
}


.btn-upgrade-modal:visited {
  text-decoration: none; /* Remove o sublinhado após o link ser visitado */
}

.btn-upgrade-modal:active {
  text-decoration: none; /* Mantém sem sublinhado ao clicar */
}