@import '../../../vars.module.scss';

.informative-tooltip {
  padding: 12px 6px;

  .tooltip-title {
    font-weight: 700;
    font-size: 15px;
  }
}
.informative-container {
  display: flex;
  margin: 8px 0;

  .informative_button {
    padding: 2px 10px;
    border-radius: 4px;
    margin-right: 5px;
    font-size: 20px;

    &-active {
      background-color: white;
      color: #475467;
      border: 1px solid #D0D5DD
    }

    &-active-green {
      background-color: #4fe0a3;
      color: $white;
    }

    &-disabled {
      background-color: #010C28;
      color: white;
    }

    .daily {
      background-color: $red;
      color: $turivius-dark;
    }
    .weekly {
      background-color: $green;
      color: $turivius-dark;
    }
    .daily {
      background-color: blue;
      color: $turivius-dark;
    }
  }
}
