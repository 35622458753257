.loadFrame {
  &.hidden {
    display: none;
  }
}

.jurimetrics {
  &-card {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;

    &.bottom {
      margin-top: 20px;
    }

    &-header {
      padding-bottom: 24px;
      h6 {
        color: #000000;
      }
    }
  }
}

.loading-jurimetrics {
  background-color: #000000;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#grafico *,
#novos-graficos * {
  overflow: visible !important;
  visibility: visible !important;
  font-family: Arial, sans-serif !important; 
}
