.resume-ia-bulkaction-context {
  margin-top: 82px;
  margin-left: 16px;
}

.title-resume-ia {
  display: flex;
  gap: 6px;
  align-items: center;
  text-align: start;
  font-size: 16px;
  line-height: 28px;
  font-family: 'Poppins', ui-sans-serif, system-ui, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 600;
  flex-direction: row;
}

.title-beta {
  width: 36px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #fdb022;
  color: white;
  border-radius: 9999px;
  cursor: default;
  font-family: Poppins;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0%;
  gap: 4px;
}

.info_resume_ia {
  width: 68%; /* 68 % */
  height: 40px;
  background-color: #d1e9ff;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px #0000000f;
  box-shadow: 0px 1px 2px 0px #0000000a;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.info-pro {
  width: 29px;
  height: 16px;
  border-radius: 999px;
  background-color: #101828;
  color: white;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  margin-left: 4px;
  margin-right: 4px;
}

.info-icon-container {
  width: 28;
  height: 28;
  top: 130px;
  left: 1389px;
  border-radius: 4px;
  border-width: 1px;
  border: 1px solid #e4e7ec;
  margin-left: 14px;
  margin-right: 4px;
}

.info_generate_insights-container {
  margin-top: 20px;
  span {
    margin-left: 4px;
    margin-right: 4px;
    font-weight: 500;
  }
}

.info_generate_insights {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
