.box-content {
  border-left: 1px solid #eaecf0;
  margin-left: 19px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 14px;
  padding-bottom: 18px;
  font-weight: 400;
}

.title-filter {
  font-weight: 500;
  margin-bottom: 12px;
  color: #101828;
  font-size: 16px;
}

.item-collapse-entities-0 {
  color: #101828;
  font-weight: 600;
}


.item-collapse-entities-1 {
  font-weight: 400;
  color: #475467;
  
}

input[type='checkbox']:checked {
  background: #101828 !important;
  border-color: #101828 !important;
}

.tax-filter-selected {
  color: #101828;

}

input[type='checkbox']:indeterminate.custom-checkbox-taxfilter {
  background-color: #fff;
}

input[type='checkbox']:indeterminate.custom-checkbox-taxfilter::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 2px;
  background-color: #053ac8 !important;
  transform: translate(-50%, -50%);
}