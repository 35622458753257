.monitoring-settings-content {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .monitoring-settings-label {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #363636;
  }

  .term-monitoring {
    margin-bottom: 10px;
    border-radius: 4px;
  }

  &_input-drop {
    border-radius: 4px;
  }

  &_toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;

    .rs-toggle-checked .rs-toggle-presentation {
      background-color: #010C28 !important;
      border-color: #010C28 !important;
    }
    .rs-toggle-checked:hover {
      .rs-toggle-presentation {
        background-color: #010c28c3 !important;
        border-color: #010c28c3 !important;
      }
    }

    label {
      font-size: 15px;
      font-weight: 700;
    }
  }

  &.error {
    .rs-input {
      border-color: rgb(235, 124, 124);
    }
  }

  .rs-input:focus,
  .rs-input:focus-within {
    box-shadow: none;
  }
}
