.title-resumo-ia {
  display:flex;
  flex-direction: row;
   gap: 8px; 
   padding: 2px 0px; 
   align-items: center;
   color: #0069F5
}

.title-resumo-ia :hover{
  cursor: pointer;
  color: #032378;
}

.backgroundIAOpen {
  background-color: rgb(0 0 0 / 0.2);
  position: fixed;
  inset: 0px;
  width: 100%;
  height: 100%;
  z-index: 200;
}

.DialogContentClass {
  z-index: 201 !important;
  width: 768px;
  max-width: 768px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 32px;
  padding-bottom: 32px;
  left: auto;
  right: 56px;
  top: 20%;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border: 1px solid #e4e7ec;
}

.beta-title-resume-decision {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background-color: #fdb022;
  color: #ffffff;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
  width: 44px;
  height: 24px;
  padding-top: 2px;
  padding-right: 8px;
  padding-bottom: 2px;
  padding-left: 8px;
  gap: 4px;
}

.desc-resume-decision {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
}

.scroll-resume-ia::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.scroll-resume-ia::-webkit-scrollbar-thumb {
  background-color: #d1e9ff;
  border-radius: 8px;
}

.scroll-resume-ia::-webkit-scrollbar-thumb:hover {
  background-color: #c8ddef;
}

.scroll-resume-ia::-webkit-scrollbar-track {
  background-color: #f5faff !important;
  border-radius: 8px;
}
