.resume-ia-result-container {
  background-color: #010C28;
  margin: 32px 38px 14px 74px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desc-resume-ia-result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 12px;
  margin-left: 18px;

  span {
    font-family: Rubik;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
  }

  img {
    width: 36px;
    height: 36px;
    border-radius: 8px;
  }
}

.button-resume-ia-result {
  display: flex;
  width: 153px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #d0d5dd;
  color: #101828;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
  text-align: center;
  margin-right: 18px;
}
