  .resume-ia-bulkaction-container {
    position: fixed;
    background-color: #eff8ff;
    z-index: 209 !important; 
    width: 742px;
    max-width: 742px;
    height: 100vh;
    right: 0%; 
    top: 0%;
    overflow-y: auto;
  }

  .close-button-resume-in-bulkaction{
    position: absolute;
    top: 49px;
    left: 16px;
    display: flex;
    align-items: center;
    width: 56px;
    height: 20px;
    gap: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    background-color: transparent;
    color: #667085;
  }


  .close-circle-button-resume-in-bulkaction{
    position: absolute;
    top: 49px;
    right: 16px;
    border-radius: calc(var(--radius) - 4px);
    --tw-ring-offset-color: hsl(var(--background));
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

  }