.backgroundIAOpenBulkAction {
  background-color: rgb(0 0 0 / 0.3);
  position: fixed;
  inset: 0px;
  height: 100vh;
  z-index: 200;
}

.button-resume-decision {
  background-color: #0069f5;
  width: 202px;
  height: 36px;
  padding: 0px 4px;
}

.text-button-resume-decision {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  gap: 6px;
  white-space: nowrap;
}

.text-count-cards-selected-resume {
  background-color: white;
  color: #053ac8;
  width: 18px;
  height: 16px;
  border-radius: 999px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
}
