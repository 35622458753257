.recursivefilter-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #fff;
  height: 202px;
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0px 1px 3px 0px #0000000f;
  color: #101828;
  height: auto;
  padding: 16px 26px;
  position: relative;
}

.checkbox-list-wrapper {
    position: absolute;
    right: 0%; 
    top: 70%; 
    background: white;
    box-shadow: 0px 1px 3px 0px #0000000f;
    padding: 10px;
    width: 200px;
}


.recursivefilter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Poppins;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
  vertical-align: middle;
}

.recursivefilter-search-container {
  position: relative;
  margin-bottom: 15px;

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    pointer-events: none;
  }

  .clear-search {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #696969;
  }

  .recursivefilter-search-input {
    width: 100%;
    height: 40px;
    gap: 8px;
    border: 1px solid #e4e7ec;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
  }

  input[type='text']::placeholder {
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0%;
    color: #667085;
  }

  input[type='text']:focus {
    outline: none;
  }
}

.checkbox-recursal-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  gap: 8px;
  border-radius: 4px;
  padding-right: 8px;
}

.checkbox-recursal-container:hover {
  background: #f8fafc;
}

.checkbox-recursal-context {
  display: flex;
  gap: 8px;
  border-radius: 4px;
  padding-right: 8px;
  padding-left: 8px;

  color: #101828;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
}

.recursal-count-0 {
  background-color: #eaecf0;
  color: #475467;
  border-radius: 15px;
  margin-left: auto;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal-recursalfilter {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e4e7ec;
  right: 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #667085;
  border-radius: 4px;
  padding: 12px;

  .input-checkbox-modal-recursive {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
