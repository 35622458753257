
// EXPLANATION: Essa parte controla as abas de resutlado da busca e jurimetria

// Cuidado essa classe também controla o componente dentro de salvar coleções
.box-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 8px;
    padding-right: 16px;
    padding-bottom: 2px;
    text-decoration: none;
    color: inherit;

    &:hover {
        color: #010C28; 
        // text-decoration: none;
      }
}

.tv-nav_tabs-items {
    

    color:  #667085 !important;
    /* Button & Links/Regular S 14 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.tv-nav_tabs-items-active {
    color: #101828 !important;
    /* Button & Links/Semibold S 14 */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.resultspagesize{
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 8px;
    padding-right: 12px;
    padding-left: 12px;
}
