@import '../../vars.module.scss';

// ---------------------------------------------------------------
// EXPLANATION: Essas duas classes controlam a borda do card de MONITORAMENTO
// ---------------------------------------------------------------
.monitoring-card {
  border: 1px solid $gray-border-alpha
}

// -----------------

.monitoring {
  &-create-new {
    &_modal-footer {
      display: flex;
      justify-content: center;
      gap: 40px;
    }
  }

  &-search {
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      border: none !important;
      h5 {
        color: $gray900;
      }
    }

    &_group {
      display: flex !important;
      align-items: center;
      gap: 8px;
      input {
        height: 40px !important;
        border: 1px solid $gray200 !important;
        border-radius: 8px !important;
        font-family: $poppins !important;
        font-size: 14px !important;
      }

      button {
        height: 40px !important;
        background-color: #FFFFFF !important;
        padding: 0 32px !important;
        border: 1px solid #D0D5DD !important;
        color: #475467 !important;
        border-radius: 8px !important;
        display: flex;
        gap: 16px;
      }
    }
  }

  &-order-by {
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding: 5px;

    &_label {
      margin-right: 8px;
    }
  }

  &-my-session {
    padding: 0 24px 24px 24px;
  }

  &-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 48px;
    text-align: center;

    h4 {
      color: $gray800;
    }

    p {
      color: $gray500;
    }
  }

  &-card {
    background-color: $white;
    border-radius: 16px;
    padding: 24px;

    &_btn-action {
      background-color: transparent !important;
      font-size: 20px !important;
      color: $blue800 !important;

      &:hover {
        color: $blue500 !important;
        opacity: 1 !important;
      }
    }

    &_whisper {
      padding: 16px !important;

      &-title {
        padding-bottom: 8px;
        margin-bottom: 12px;
        border-bottom: 1px solid $gray600;
      }
    }

    &_header {
      display: flex;
      justify-content: space-between;

      &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        color: $gray900;
        p {
          font-size: 18px;
        }
      }
    }

    &_body {
      &-scope_with-results {
        &-updates {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        &-badge {
          background-color: #EAECF0;
          color: #475467;
          padding: 2px 6px;
          font-weight: 600;
          margin: 4px 0;
          border-radius: 12px;
        }
      }
    }

    &_footer {
      display: flex;

      gap: 16px;
      align-items: center;
      justify-content: space-between;

      &-old-updates {
        @include typography-button_preset;

        border-radius: 4px;
        background-color: transparent !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        border: 1px solid $gray300 !important;
        color: $gray600;
        padding: 6px 16px;
        position: relative;

        white-space: nowrap !important;
        width: 100%;

        &:hover {
          border: 1px solid $gray500 !important;
          transition: ease 0.8s;
        }

        &:active {
          background-color: $gray100;
          border: 1px solid $gray200 !important;
        }

        &_actions {
          display: flex;
          gap: 16px;
          position: relative;

          &-menu {
            width: 280px;
            max-height: 320px;
            overflow-y: scroll;
            position: absolute;
            left: 0;
            top: 48px;
            background-color: white;
            border: 1px solid $gray200;
            border-radius: 12px;

            z-index: 100;

            box-shadow:
              0px 4px 6px -2px rgba(0, 0, 0, 0.03),
              0px 12px 16px -4px rgba(0, 0, 0, 0.08);

            &-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 20px 16px;
              gap: 8px;
              &:hover {
                background-color: $blue50 !important;
              }
            }
            &-span {
              color: $gray900;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }
      &-new-updates {
        @include typography-button_preset;
        border-radius: 4px;
        padding: 6px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        background-color: #010C28;
        color: $white;
        width: 100%;
        white-space: nowrap !important;

        &_outline {
          background-color: $white;
          color: $blue700;
        }

        &:hover {
          background-color: $blue600;
          transition: ease 0.8s;
        }

        &:active {
          background-color: $blue800;
        }
      }

      @media (max-width: 768px) {
        flex-direction: column !important;
        width: 100%;
      }
      @media (min-width: 768px) and (max-width: 1245px) {
        flex-direction: column !important;
        width: 100%;
      }
    }
  }
}
