@import '../../../../../vars.module.scss';
@import '../../../../../styles/variables/_colors.scss';




// --------------------------------------------------------------------------------------------------------
// Experiência de pesquisa
// --------------------------------------------------------------------------------------------------------
/* Efeito de escurecimento */
.overlay-active::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Escurece com transparência */
  z-index: 999; /* Certifique-se de que está acima dos elementos */
}

/* Certifique-se de que o modal de filtros fique acima do overlay */
.advanced-filters-container {
  position: relative;
  z-index: 1000;
}

#search-history-popover {
  left: 0 !important;
}

// --------------------------------------------------------------------------------------------------------

.input-result {
  height: 50px !important;
  background-color: transparent;
  font-size: 16px;
}

.input-result::placeholder {
  color:#98A2B3 ;
}

.resultpage-header {
  height: 120px;
  padding: 80px 48px;

  &_sub {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    position: relative;

    .monitoring-term {
      width: 50%;
      justify-self: flex-start !important;
      display: flex;
      gap: 8px;
      margin-top: 20px;
    }
  }
}

#btn-select-entities-result {
  position: relative;
  background-color: transparent;
  border: none;
  border-left: 1px solid #e4e7ec;
  margin-top: -40px;
  right: -76%;
  z-index: 999;
}

.separator {
  width: 1px;
  height: 34px;
  background-color: #e4e7ec;
  margin-top: 8px;
  margin-left: 18px;
}


// ---------------------------------------------------------------------------------------------------------
// EXPLANATION: controla o searchbar
// ---------------------------------------------------------------------------------------------------------

.search-bar-result {
  width: 98%;
  border-radius: 8px;
  border: 1px solid #E4E7EC;
  box-shadow: 0px 4px 25px 0px rgba(209, 233, 255, 0.30);
  #btn-search:hover, #btn-search:focus {
    background: none; /* Remove mudanças no fundo */
    color: inherit; /* Mantém a cor original */
    border: none; /* Remove borda no hover */
    box-shadow: none; /* Remove sombra no hover */
    transition: none; /* Remove transições no hover */
  }
}

.search-bar-result:focus,.search-bar-result:focus-within {
  outline: none !important ;
  border: 1px solid #0D91FE;
}

// ---------------------------------------------------------------------------------------------------------


.menu-button {
  background-color: #ffffff;
  max-width: auto;
  padding-left: 30px;
  padding-right: 20px;
}
.icon-button {
  background-color: #ffffff;
}

#btn-search-result {
  background-color: #010C28;
  border-radius: 4px;
  color: #fff;
  font-weight: 5700;
  padding: 20px 20px;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 3px;
  width: 124px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

// EXPLANATION: Esse código é o autocomplete
.search-result div {
  display: flex;
  gap: 11px;
  text-transform: lowercase;
  color: #475467;
}

.grid-search-options {

  #btn-search {
    background-color: #010C28;
    border-radius: 4px;
    color: #fff;
    font-weight: 5700;
    padding: 24px 24px;
    font-size: 14px;
    line-height: 24px;
    height: 48px;
    margin-top: 3.5px;
    margin-right: 3.5px;
    width: 124px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  #btn-select-entities {
    display: flex;
    gap: 7px;
    background-color: transparent;
    border-radius: 0px;
    font-weight: 400;
    color: #667085;
    padding: 16px;
    font-size: 12px;
    border-left: 1px solid #d0d5dd;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #btn-filters-advanced {
    display: flex;
    gap: 7px;
    background-color: transparent;
    border-radius: 0px;
    font-weight: 400;
    color: #667085;
    padding: 16px;
    font-size: 12px;
    border-left: 1px solid #d0d5dd;
    margin-top: 5px;
    margin-bottom: 5px;
    height: auto;
  }

  //------------------------------------------------------------------------------------------
  // EXPLANATION: As classes a seguir controlam a searchbar
  //------------------------------------------------------------------------------------------
  
  // EXPLANATION: isso aqui serve para que o botão de switch em cima da search bar cubra a borda da searchbar
  .button-input {
    display: flex;
    margin-bottom: -1px; 
  }

  .icon-button {
    margin-top: 10px;
    background-color: #0069f5;
  }

  .btn-left,
  .btn-right {
    font-size: 16px;
    padding: 10px 44px 10px 47px;
    border-right: none;
    border-radius: 0;
    background: #ffffff;
    color: #101828 !important;
    border-bottom: none;
    box-shadow: none; /* Remove qualquer sombra do botão */
  }

  //------------------------------------------------------------------------------------------
  // EXPLANATION: As classes a seguir controlam os switchs de troca da searchbar  
  //------------------------------------------------------------------------------------------
  
  //EXPLANATION: Essa classe controla o botão esquerdo da search
  .btn-left {
    z-index: 3; // serve para que o switch fique acima da search bar e depois cubra ela
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px !important;
    // border-color: #0069f5 !important;
  }

  .btn-right {
    z-index: 3; // serve para que o switch fique acima da search bar e depois cubra ela
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-left: -15px;
  }

  .btn-left{
    border-bottom: none;
  }


  .clicked {
    position: relative;
    
    border: 1px solid #D1E9FF;
    // border-color: !important;
    border-bottom: none;
  }

  .clicked > .text-btn-search {
    z-index: 102;
    border-bottom: 2px solid #010C28;
    display: inline-block;
    padding-bottom: 1px;
    font-weight: 500;
    font-size: 14px;
  }
  
  .unclicked {
    // border-bottom: 2px solid #f2f4f7;
    background: #e8e8e8;
    z-index: 0;
    margin-right: -2px;
  }
  //------------------------------------------------------------------------------------------






  .tgg-advanced-search {
    background-color: $white;
    border: 1px solid #2e2e2e;
    border-radius: 4px;
  }

  .rs-input-group-btn:last-child {
    border-radius: 6px;
  }
}

.input-icon-search {
  position: absolute;
  z-index: 1;
  padding-top: 18px;
  padding-left: 24px;
  color: #d0d5dd;
}

.input-search-section {
  display: flex;
}

.operators-container {
  padding: 10px 0;
}

.panel-container {
  padding: 10px 20px;

  .column-item-option {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .line-labels {
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 10px !important;

    .column-item-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .filter-section-content {
        width: 100%;
      }
    }

    .column-item-label-single {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      .title-label-single {
        width: 100%;
        font-weight: 700;
        color: $black;
        margin-bottom: 10px;
      }

      .container-item-label-single {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .item-label-single {
        min-height: 0;
        width: 100%;
        border-radius: 5px;
      }

      .filter-section-content {
        //margin-top: 15px;
        width: 100%;

        .input-field-header {
          width: 100%;
          display: flex;
          flex-direction: column;

          label {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .title-label {
    font-weight: 700;
    color: $black;
    margin-right: 10px;
    white-space: nowrap;
  }
}

.advanced-search {
  border-radius: 0px 0px 10px 10px !important;
  box-shadow: 0px 4px 10px #ddd;
  background-color: #ffffff;
  margin-top: 10px;

  &.hide-header .rs-panel-header {
    display: none;
  }

  .rs-panel-header {
    background-color: #f3f3f3;
    padding: 0;

    .rs-icon {
      top: 15px;
      color: $black-deep;
      right: 24px;
    }

    .rs-panel-title {
      font-weight: 700;
      font-size: 12px;
      padding: 10px 10px;
    }
  }

  .rs-row {
    margin: 0px;

    &::after {
      position: absolute;
    }

    &::before {
      position: absolute;
    }
  }

  .search-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

#search-history-popover {
  z-index: 81;
  small {
    color: #98a2b3;
    font-size: 12px;
    font-weight: 500;
  }

  .rs-popover-arrow {
    display: none;
  }

  .rs-popover-title {
    color: #888;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1rem;
  }

  .rs-popover-content {
    max-height: 530px;
    overflow-y: auto;
    padding: 0px 8px;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #555;
    }

    p {
      font-size: 14px;
      color: #475467;
      font-weight: 500;
      font-size: 14px;

      cursor: pointer;
      padding: 4px;
      margin: 0px;

      &:hover {
        background-color: #eff8ffc5;
      }
    }

    &.rs-popover[class*='placement-bottom'] {
      margin-top: -1px !important;
    }
  }
}

#refEntit {
  width: 100%;
  z-index: -1;
  visibility: hidden;
}

#search-entity-popover {
  margin-top: 8px !important;
  border-radius: 0px 0px 4px 4px !important;

  .rs-popover-arrow {
    display: none;
  }

  .rs-popover-title {
    color: #888;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1rem;
  }

  .rs-popover-content {
    max-height: 450px;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #555;
    }

    p {
      font-size: 14px;
      color: $turivius;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      padding: 4px;
      margin: 0px;
      border-radius: 2px;

      &:hover {
        background-color: #eee;
      }
    }
  }
}

// ---------------------------------------------------------------------------------------------
// EXPLANATION: Esse classe controla as configs de uma div que está encapsulando a searchbar
// ---------------------------------------------------------------------------------------------
#search-card-query-col {
  background-color: #fff; 
  border-radius: 8px;
  box-shadow: 
  0px 1px 3px 0px rgba(0, 0, 0, 0.06), /* Sombra 1 */
  0px 1px 2px 0px rgba(0, 0, 0, 0.04); /* Sombra 2 */
  border: 1px solid #D1E9FF!important;
  border-top-left-radius: 0;
}


// ---------------------------------------------------------------------------------------------

@media screen and (max-width: 1540px) {
  #btn-select-entities-result {
    right: -67%;
    z-index: 999;
  }
}

@media screen and (max-width: 1490px) {
  #btn-select-entities-result {
    right: -66%;
    z-index: 999;
  }
}

@media screen and (max-width: 1440px) {
  #btn-select-entities-result {
    right: -66%;
    z-index: 999;
  }

  .search-container {
    display: block;

    .search-bar {
      width: 100% !important;
    }

    .search-button {
      width: 100% !important;
    }
  }

  #search-entity-popover {
    margin: auto !important;
    margin-top: 8px !important;
    border-radius: 0px 0px 4px 4px !important;
    // left: 370px !important;
    // width: 100%;

    .rs-popover-content {
      max-height: 350px;
      overflow-y: auto;
    }
  }
}

//Configurações de Responsividade
@media screen and (max-width: 1041px) {
  #btn-select-entities-result {
    right: -46%;
    z-index: 999;
  }

  .column-item-label-single {
    display: block;
  }

  .search-container {
    .search-bar {
      background-color: #fff;
      width: 100% !important;
    }

    .search-button {
      width: 10% !important;
    }
  }
}
@media screen and (max-width: 970px) {
  #search-card-query-col {
    display: flex;
    flex-direction: column;

    .input-search-section {
      display: flex;
    }
  }

  #btn-filters-advanced {
    width: 50%;
    border-left: none !important;
  }

  #btn-search {
    width: 50% !important;
  }

  .grid-search-options {
    .btn-rigth {
      width: 70%;
    }
    .btn-left {
      width: 30%;
    }
  }
}

@media screen and (max-width: 910px) {
  #btn-select-entities-result {
    right: -46%;
    z-index: 999;
  }

  .search-container {
    display: block;

    .search-bar {
      width: 100% !important;
    }

    .search-button {
      width: 100% !important;
    }
  }
}

.rs-popover[class*='placement-bottom'] {
  margin-top: -1px !important;
}

.rs-popover {
  box-shadow: none !important;
  filter: none !important;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

//----------------------------------------------------------
//EXPLANATION: Essa parte controla o escurecimento na home
//----------------------------------------------------------

.focused {
  position: relative;
  z-index: 1;
}

.focused-container {
  position: relative;
  z-index: 50;
  transition: all 0.5s ease-in-out
}

.focused-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
}
//----------------------------------------------------------



//----------------------------------------------------------
// CLASSES AUTOCOMPLETE
//----------------------------------------------------------


// EXPLANATION: Essa parte tira a sombra do botão 
.option-item.collection_history {
  color: #7F56D9;
  font-size: 16px;
  font-weight: 400;
}

.option-item.ementa {
  font-size: 16px;
  font-weight: 400;
}

.option-item span{
  font-size: 16px;
}

.option-item.selected {
  background-color: #F1F5F9;
  // border-left: 2px solid #007BFF;
  border-radius: 8px;
  font-weight: 500;
}

// EXPLANATION: Essa classe controla o efeito de destaque atribuido as palavras de sugestões conforme elas vão aparecendo.
.highlighted-text {
  font-weight: bold;
  display: inline; /* Garante que o span não crie quebras */
  padding: 0;
  margin: 0;
  line-height: inherit;
  font-size: 16px;
}

.option-item {
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
}

.option-item:hover {
  background-color: #F1F5F9; /* Cor de fundo ao passar o mouse */
  border-radius: 8px;
}

.rs-input-group-inside {
  // border-radius: 0px;
  // border-top-left-radius: '0px'; // Remove o border-radius apenas no canto superior esquerdo
}

.rs-input-group-inside:focus-within,.rs-input-group-inside:focus,.search-bar:focus, .search-bar:focus-within{
  outline: none !important;
  border-color: none !important;
}

//----------------------------------------------------------