.title-generic-singular {
  margin-top: 10px;
  margin-bottom: 12px;

  padding: 0px 14px;

  height: 27px;
  border-radius: 999px;
  background-color: #eff8ff;
  color: #101828;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: start;
}

.title-about-decision-singular {
    margin-top: 10px;
    margin-bottom: 12px;
    width: 240px;
    height: 27px;
    border-radius: 999px;
    background-color: #eff8ff;
    color: #101828;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0%;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
  }

  .title-final-decision-singular {
    margin-top: 24px;
    margin-bottom: 12px;
    width: 151px;
    height: 27px;
    border-radius: 999px;
    background-color: #eff8ff;
    color: #101828;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0%;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
  }

  .resume-result-desicion-singular {
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0%;
    color: #475467;
    margin-bottom: 30px;
    padding-left: 10px;
  }
  