.tv-nav {
  &_tabs {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 8px;
    
    &-items {
      color: $gray500 !important;
      font-size: $body-5;
      padding-bottom: 8px;
      border-bottom: 3px solid $gray200;
      a {
        cursor: pointer;
      }
      &-active {
        color: $blue600 !important;
        border-bottom: 3px solid $gray900;
      }
    }
  }
}
