@import '../../../vars.module.scss';

.jurimetrics-trabalhista-iframe {
  height: 100vh;
  overflow-y: hidden;
  background-color: #f6f6f6;

  .conteiner-iframe {
    width: 100%;
    height: 85%;
    iframe {
      width: 100%;
      height: 90%;
    }
  }

  .conteiner-button-back {
    padding: 20px 5% 10px;
    height: 10%;

    .btn-back {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      background-color: $turivius-dark;
      color: $white;
      font-weight: 700;
      width: 26%;
      border-radius: 4px;
    }
  }
}

.card-case-grid {
  display: flex;
  justify-content: space-around;
}

.case-card-content {
  width: 500px;
  min-width: 500px;
}


.search-jurimetrics {
  margin: 80px 0px 80px;
  text-align: center;
  
  h6 {
    font-style: 'Rubik';
    color: #010C28;
    font-size: 32px;
    font-weight: 700;
  }

  p{
    color:#475467 ;
  }
}

@media screen and (max-height: 750px) {
  .jurimetrics-trabalhista-iframe {
    .conteiner-iframe {
      iframe {
        height: 80%;
      }
    }
  }
}
