.__floater__arrow {
  span {
    svg {
      polygon {
        stroke: $blue700;
        stroke-width: 2;
        stroke-linejoin: round;
      }
    }
  }
}

.tooltip-tour {
  background-color: $blue700;
  color: $white;
  border-radius: 8px;
  padding: 14px 21px;
  font-family: $poppins !important;
  font-size: 14px;
  min-width: 500px;
  max-width: 550px;
  position: relative;
  h5 {
    font-size: 16px;
    font-weight: 600;
    padding: 14px 0 15px 0;
  }

  p {
    font-size: 14px !important;
    margin-bottom: 25px;
  }

  &_close {
    position: absolute;
    right: 21px;
    background: transparent;
    top: 14px;
  }

  &_footer {
    margin-top: 10px;
    border-top: 1px solid rgba(1, 12, 40, 0.2);
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttonsGroup {
      display: flex;
      justify-content: space-between;

      button {
        margin-left: 10px;
      }
    }
  }
}

.bg-white {
  background-color: $white !important;
}

.text-white {
  color: #344054 !important;
}

// .text-gray {
//   color: $cinza_icons;
// }
