.navbar {
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  font-size: 15px;

  .rs-nav-item-active {
    font-weight: 700;
    color: #101828 !important;
  }

  .rs-nav-item-active::before {
    background-color: #101828 !important;
  }

  .rs-nav-bar {
    border-top: 2px solid rgb(223, 223, 223) !important;
  }
}
