.notification-resume-container {
  width: 400px !important;
  height: 473px;
  align-items: center;
}

.notification-resume-context {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title-notification {
  width: 352px;
  height: 20px;
  font-family: Rubik;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0%;
  color: #101828;
  margin-top: 10px;
}

.describe-notification {
  width: 352px;
  height: auto;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #667085;
}

.page-indicator {
  display: flex;
  gap: 12px;
  margin-top: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e4e7ec;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #053ac8;
}

.page-navigation {
  display: flex;
  gap: 12px;
}

.next-button-notification {
  margin-top: 10px;
  background-color: #053ac8;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
  width: 101px;
  height: 28px;
  gap: 8px;
}

.previous-button-notification {
  border: 1px solid #e4e7ec;
  width: 85px;
  height: 28px;
  border-radius: 4px;
  gap: 8px;
  background-color: white;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
}

.close-button-notification {
  width: 79px;
  height: 28px;
  border-radius: 4px;
  gap: 10px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
  background-color: #053AC8;
}
