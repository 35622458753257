.tv-btn {
  @include typography-button_preset;
  border: inherit;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: fit-content;

  &-sm {
    padding: 8px 16px;
    font-size: $body-4;
    line-height: $shorter;

    &_full {
      max-height: 100vh;
      max-width: 100vw;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      font-size: $body-2;
      padding: 8px 0;
    }
  }

  &-md {
    padding: 12px 16px;
    font-size: $body-3;
    line-height: $shorter;

    &_full {
      max-height: 100vh;
      max-width: 100vw;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      font-size: $body-2;
      padding: 12px 0;
    }
  }

  &-lg {
    padding: 14px 24px;
    font-size: $body-2;

    &_full {
      max-height: 100vh;
      max-width: 100vw;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      font-size: $body-2;
      padding: 14px 0;
    }
  }

  &-primary {
    background-color: #101828;
    color: $white;

    &_outline {
      background-color: $white;
      color: $blue700;
    }

    &:hover {
      background-color: $blue600;
      transition: ease 0.8s;
    }

    &:active {
      background-color: $blue800;
    }
  }

  &-primary-dark {
    --tw-bg-opacity: 0.2;
    background-color: rgba($blue900, var(--tw-bg-opacity));
    color: $white;

    &_outline {
      background-color: $white;
      color: $blue800;
    }

    &:hover {
      --tw-bg-opacity: 0.1;
      background-color: rgba($blue900, var(--tw-bg-opacity));
      transition: ease 0.8s;
    }

    &:active {
      background-color: $blue800;
    }
  }

  &-blue900 {
    background-color: #010c28;
    color: $white;

    &_outline {
      background-color: $white;
      color: $blue800;
    }

    &:hover {
      background-color: #010c28;
      transition: ease 0.3s;
    }

    &:active {
      background-color: #010c28;
    }
  }

  &-secondary,
  &-secondary_outline {
    background-color: transparent;
    border: 1px solid $gray300;
    color: $gray600;

    &:hover {
      color: $blue900 !important;
      background-color: transparent;
      border: 1px solid $gray500;
      transition: ease 0.8s;
    }

    &:active {
      background-color: $gray100;
      border: 1px solid $gray200;
    }
  }

  &-minimal,
  &-minimal_outline {
    background-color: transparent;
    color: $gray600;

    &:hover {
      color: $blue900;
      transition: ease 0.8s;
    }

    &:active {
      background-color: $gray100;
      color: $blue900;
    }
  }

  &-success {
    background-color: $success500;
    color: $white;

    &_outline {
      border: 1px solid $success500;
      border-radius: 4px;
      background-color: transparent;
      color: $success500;

      &:hover {
        border: 1px solid $gray500;
        color: $success600;
      }

      &:active {
        border: 1px solid $gray200;
        background-color: $success100;
        color: $success800;
      }
    }

    &:hover {
      background-color: $success600;
      transition: ease 0.8s;
    }

    &:active {
      background-color: $success800;
    }
  }

  &-danger {
    background-color: $danger500;
    color: $white;

    &_outline {
      border: 1px solid $danger500;
      border-radius: 4px;
      background-color: transparent;
      color: $danger500;

      &:hover {
        border: 1px solid $gray500;
        color: $danger600;
      }

      &:active {
        border: 1px solid $gray200;
        background-color: $gray100;
        color: $danger800;
      }
    }

    &:hover {
      background-color: $danger600;
      transition: ease 0.8s;
    }

    &:active {
      background-color: $danger800;
    }
  }

  &-white {
    background-color: $white;
    color: $blue700;

    &_outline {
      border: 1px solid $white;
      background-color: transparent;
      color: $white;

      &:hover {
        background-color: $white;
        color: $blue700;
        transition: ease 0.8s;
      }

      &:active {
        background-color: $gray100;
        color: $blue700;
      }
    }

    &:hover {
      background-color: transparent;
      color: $white;
      border: 1px solid $white;
      transition: ease 0.8s;
    }

    &:active {
      background-color: $gray100;
      color: $blue700;
    }
  }

  &-disabled {
    cursor: not-allowed;
  }

  &-block {
    cursor: not-allowed;
  }

  &_favorability {
    &-favorable {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 4px 16px 4px 4px;
      border-radius: 999px;
      width: fit-content;
      border: 1px solid $gray200;
      background-color: transparent;

      span {
        color: $gray600;
      }

      &_icon {
        padding: 4px;
        background-color: $success200;
        border: 4px solid $success100;
        border-radius: 999px;
        width: 32px;
        height: 32px;
      }

      &:hover {
        transition: 0.45s;
        background-color: $success50;
        border: 1px solid $success50;

        span {
          color: $success600;
        }
      }

      &:focus {
        span {
          color: $success700;
        }
      }
    }

    &-unfavorable {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 4px 16px 4px 4px;
      border-radius: 999px;
      width: fit-content;
      border: 1px solid $gray200;
      background-color: transparent;

      span {
        color: $gray600;
      }

      &_icon {
        padding: 4px;
        background-color: $warning200;
        border: 4px solid $warning100;
        border-radius: 999px;
        width: 32px;
        height: 32px;
      }

      &:hover {
        transition: 0.45s;
        background-color: $warning50;
        border: 1px solid $warning50;

        span {
          color: $warning500;
        }
      }

      &:focus {
        span {
          color: $warning700;
        }
      }
    }

    &-partially {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 4px 16px 4px 4px;
      border-radius: 999px;
      width: fit-content;
      border: 1px solid $gray200;
      background-color: transparent;

      span {
        color: $gray600;
      }

      &_icon {
        padding: 4px;
        background-color: $blue200;
        border: 4px solid $blue100;
        border-radius: 999px;
        width: 32px;
        height: 32px;
      }

      &:hover {
        transition: 0.45s;
        background-color: $blue50;
        border: 1px solid $blue50;

        span {
          color: $information600;
        }
      }

      &:focus {
        span {
          color: $information700;
        }
      }
    }

    &-other {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 4px 16px 4px 4px;
      border-radius: 999px;
      width: fit-content;
      border: 1px solid $gray200;
      background-color: transparent;

      span {
        color: $gray600;
      }

      &_icon {
        padding: 5px;
        background-color: $gray200;
        border: 4px solid $gray100;
        border-radius: 999px;
        width: 32px;
        height: 32px;
      }

      &:hover {
        transition: 0.45s;
        background-color: #f3fcfb;
        border: 1px solid #f3fcfb;

        span {
          color: #395f5a;
        }
      }

      &:focus {
        span {
          color: #4b7c76;
        }
      }
    }

    &-suggest {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 4px 16px 4px 4px;
      border-radius: 999px;
      width: fit-content;
      border: 1px solid $gray200;
      background-color: transparent;

      span {
        color: $gray600;
      }

      &_icon {
        padding: 4px;
        background-color: #e7e5e4;
        border: 4px solid $purple100;
        border-radius: 999px;
        width: 32px;
        height: 32px;
      }

      &:hover {
        transition: 0.45s;
        background-color: $purple50;
        border: 1px solid $purple50;

        span {
          color: $purple700;
        }
      }

      &:focus {
        span {
          color: $purple800;
        }
      }
    }
  }
}

.btn-group {
  display: flex;
  gap: 8px;
  height: 36px;
}
