.drawer {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: #eff8ff;
  overflow-x: hidden;
  transition: 0.5s;
  // box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);   box shadow com o backdrop
  box-shadow: -2px 0 5px rgba(189, 189, 189, 0.399);
}

.drawer-content {
  padding-left: 20px;
  overflow: hidden;
}

.header-filter-home {
  display: flex;
  justify-content: space-between;
  padding-top: 46px;
  padding-left: 26px;
  font-weight: 700;
  font-size: 16px;
}

.drawer-content .tv-filters_list {
  margin-top: 80px;
  height: 81vh;

  @media (max-width: 1200px) {
    margin-top: 2px;
    height: 80vh;
  }
}

.drawer-content .tv-btn {
  // padding: 20px;
}

.button-drawer {
  padding-top: 18px;
  padding-right: 18px;
}

.drawer.open {
  width: 450px;
}

// EXPLANATION: Essa parte controla o escurecimento da tela quando abre o modal lateral
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);  // background do backdrop
  z-index: 4;
}

.drawer .box-filter-header {
  display: none;
}
