.title-help-center {
  color: white;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 20px 0;
}

.help-center-container {
  display: flex;
  flex-direction: column;
  margin-left: 4px;

  &_empty {
    p {
      text-align: center;
      color: #fff;
      font-size: 14px !important;
      margin: 15px;
    }
  }

  .help-center-input-group {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    height: 40px;
    background-color: #fff;
    border-radius: 4px;

    &_icon {
      display: flex;
      align-items: center;
      padding: 5px 5px 5px 10px;
    }
    &_input {
      border: none;

      &:focus,
      &:focus-visible,
      &:focus-within {
        box-shadow: none;
        border: none;
        outline: unset;
      }
    }
  }

  .rs-input-group-addon {
    background-color: #fff;
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }

  .rs-input-group:not(.rs-input-group-inside) > :last-child {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }
}
