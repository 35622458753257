.button-input-container {
  display: flex;
  width: 420px;
  height: 40px;
  align-items: center;
  margin-left: 2px;
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 1px solid #e4e7ec;
}

.btn-left-resume,
.btn-right-resume {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  justify-content: center;
}

.btn-left-resume {
  width: 151px;
  height: 40px;
  color: #010C28;
  border-bottom: 2px solid ;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  gap: 8px;
  margin-bottom: -1px;
}

.btn-right-resume {
  width: 181px;
  height: 40px;
  color: #98a2b3;
  white-space: nowrap;
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  gap: 8px;
}

.title-em-breve {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #7f56d9;
  color: white;
  border-radius: 9999px;
  cursor: default;
  width: 60px;
  height: 16px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0%;
  gap: 4px;
}

.container-results-resume-ia {
  background-color: white;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #0000000f;
  box-shadow: 0px 1px 2px 0px #0000000a;
  padding: 22px 22px;
  width: 98%;
  margin-bottom: 50px;
}

.container-about-desicion {
  border-bottom: 1px solid #e4e7ec;
  display: flex;
  flex-wrap: wrap;

  margin-top: 0px; /*Essa margin soma com a margem do box todo e fica os 32 que o isra quer*/
  margin-bottom: 18px; /*,margin between title and body*/
  padding-bottom: 8px;
}

.container-topics {
  border-bottom: 1px solid #e4e7ec;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px; /*Essa margin soma com a margem do box todo e fica os 32 que o isra quer*/
  margin-bottom: 18px; /*,margin between title and body*/
  padding-bottom: 8px;
}

.title-topics {
  padding: 0px 14px;
  height: 27px;
  border-radius: 999px;
  background-color: #eff8ff;
  color: #101828;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.title-about-decision {
  /* width: 250px; */
  height: 27px;
  padding: 0px 14px;
  border-radius: 999px;
  background-color: #eff8ff;
  color: #101828;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  
}

.context-result {
  width: 100%;
  /* min-height: 210px; */
  height: 450px;
  margin: 10px 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #475467;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: auto;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.context-result-dec-final {
  width: 100%;
  /* min-height: 210px; */
  min-height: 50px;
  max-height: 300px;
  margin: 10px 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #475467;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: auto;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.context-result-topics {
  width: 100%;
  /* height: 100px; */
  min-height: 30px;
  max-height: 160px;
  margin: 10px 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #475467;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: auto;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.context-result-links {
  width: 100%;
  min-height: 30px;
  max-height: 120px;

  margin: 10px 0px;
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #475467;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow-y: auto;
  overflow-x: hidden;
  /* text-overflow: ellipsis; */
  margin-left: 8px;
}

.context-result-links li a {
  
}



.context-result-links li a:hover {
  
}

.link-url-summary-source{
  color: #0069F5;
  text-decoration: none
}

.link-url-summary-source:hover {
  color: #032378;
  text-decoration: none
}

.container-decision-final {
  border-bottom: 1px solid #e4e7ec;
  margin-top: 20px;
  margin-bottom: 14px;
  display: flex;
  flex-wrap: wrap;
}

.title-decision-final {
  width: 151px;
  height: 27px;
  border-radius: 999px;
  background-color: #eff8ff;
  color: #101828;
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0%;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.copy-style {
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  margin-left: auto;
  padding: 0px 6px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.copy-style:hover {
  border: 1px solid #0069f5;

}

.copied-style {
  background-color: #f2f4f7;
}

.copy-all-style {
  background-color: #010C28;
  width: 151px;
  height: 36px;
  border-radius: 4px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
}

.copy-all-style:hover {
  border: 1px solid white;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
  background-color: #0069f5;
  color: white;
}

.end-desc-result-resume-ia {
  color: #98a2b3;
  font-family: Poppins;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0%;
  text-align: center;
  width: 314px;
  height: 16px;
}

.toast-msg {
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0%;
  color: #475467;
  padding-left: 4px;
}

.fullCopiedFooterStyle {
  color: #010c28;
  background-color: #f2f4f7;
  border: 1px solid #e4e7ec;
}

.selection-style-resume::selection {
  background-color: #fdff75;
}

.action-btns-resume-ia-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.action-btns-resume-ia-modal-left {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
}
